import React, {
  useState,
  useRef,
  useEffect,
  HTMLProps,
  ChangeEvent,
} from "react";
import styled, { css } from "styled-components";
import { nanoid } from "nanoid";

import { Wrapper, Label, InputWrapper } from "./Common";

interface TextFieldInputProps {
  hasContent: boolean;
  focused: boolean;
}
export const inputStyles = css<{ hasContent: boolean; focused: boolean }>`
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  height: 2em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  animation-name: MuiInputBase-keyframes-auto-fill-cancel;
  -webkit-tap-highlight-color: transparent;
  outline: 0;
  padding-left: 4px;

  ${(props) => !props.hasContent && !props.focused && `color: transparent;`}
`;
export const Input = styled.input<TextFieldInputProps>`
  ${inputStyles}
`;

interface InputProps extends HTMLProps<HTMLInputElement> {}

export interface TextFieldProps {
  label: string;
  name: string;
  value: string | null;
  inputProps?: InputProps;
  inline?: boolean;
  onChange: (value: string) => void;
  onBlur?: (value: string | null) => void;
  tag?: any;
  autoFocus?: boolean;
  className?: string;
  forceFocusStyles?: boolean;
  required?: boolean;
}

const TextField: React.FC<TextFieldProps> = (props) => {
  let { value } = props;
  value = value || "";
  const {
    inline,
    label,
    name,
    inputProps,
    onChange,
    tag,
    autoFocus,
    onBlur,
    className,
    forceFocusStyles,
    required,
  } = props;
  const [focused, setFocused] = useState(Boolean(forceFocusStyles));
  const [autoComplete] = useState(nanoid());

  const inputEl = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (autoFocus && inputEl && inputEl.current) {
      inputEl.current.focus();
    }
  }, [inputEl, autoFocus]);
  const hasContent = value.length > 0;

  const stateProps = {
    focused,
    inline,
    hasContent: value.length > 0,
  };

  return (
    <Wrapper {...stateProps} className={className}>
      <Label htmlFor={name} lifted={focused || hasContent} required={required}>
        {label}
      </Label>
      <InputWrapper {...stateProps}>
        <Input
          as={tag ? tag : undefined}
          name={name}
          id={name}
          onFocus={() => setFocused(true)}
          onBlur={() => {
            setFocused(false);
            if (onBlur) {
              onBlur(value);
            }
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value)
          }
          autoComplete={autoComplete}
          {...stateProps}
          ref={inputEl}
          {...inputProps}
          value={value}
        />
      </InputWrapper>
    </Wrapper>
  );
};
export default TextField;
